<template>
  <nuxt-picture
    preload
    class="app-img"
    width="468"
    alt="AwesomeTechStack hero image"
    height="362"
    src="/img/AwesomeTechStack_468x362.png"
    fit="contain"
  >
  </nuxt-picture>
</template>